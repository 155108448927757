export interface IAllergen {
    id: string;
    name: string;
    printableName: string;
    state: string;
}

export const EMPTY_ALLERGEN: IAllergen = {
    id: "",
    name: "",
    printableName: "",
    state: ""
}

export interface IAllergenRES {
    succeeded: boolean;
    allergen: IAllergen;
}

export interface IAllergenDeleteRES {
    succeeded: boolean;
}